import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";


const allBlogs = [
  {
    id: 30,
    title: "A Year In Books - 2024",
    image: "/images/blog/a-year-in-books-2024.png",
    filesource: "../../blogs/a-year-in-books-2024.md",
    date: "January 7, 2025",
    category: "Books",
  },{
    id: 29,
    title: "Using Proxyman to Debug App Analytics",
    image: "/images/blog/proxyman.png",
    filesource: "../../blogs/proxyman.md",
    date: "November 14, 2024",
    category: "Tech, Analytics",
  },{
    id: 28,
    title: "A Year In Books - 2023",
    image: "/images/blog/a-year-in-books-2022.png",
    filesource: "../../blogs/a-year-in-books-2023.md",
    date: "February 23, 2024",
    category: "Books",
  },
];


function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Latest Posts" />
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;